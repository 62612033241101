import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Terms from "./Screens/Terms";
import Home from "./Screens/Home";
import Contactus from "./Screens/ContactUs";
import { Provider } from "react-redux";
import HeaderComponent from "./Screens/Header";
import AboutUs from "./Screens/AboutUs";
import Login from "./Screens/Login";
import Dashboard from "./Screens/Dashboard";
import Addfixture from "./Screens/Addfixture";
import ChatScreen from "./Screens/ChatScreen";
import OtpVerify from "./Screens/OtpVerify.js";
import SignUp from "./Screens/SignUp";
import ClubProfile from "./Screens/ClubProfile.js";
import ClubLocation from "./Screens/ClubLocation.js";
import LoginAfterSigningUp from "./Screens/LoginAfterSigningUp.js";
import SubscriptionScreen from "./Screens/Subscription.js";
import DeleteProfile from "./Screens/DeleteProfile.js";
import EditProfile from "./Screens/EditProfile.js";
import ClubList from "./Screens/ClubList.js";
import { supabase } from "./Components/supabaseClient";
import PrivateRoute from "./Components/PrivateRoute";
import { store } from "./store";
import Logout from "./Screens/Logout";

export const ChatContext = React.createContext();

function App() {
  // eslint-disable-next-line
  const [user, setUser] = React.useState(null);
  // eslint-disable-next-line
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      setUser(data.user);
      setIsLoggedIn(!!data.user);
    };
    getUser();
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user ?? null);
        setIsLoggedIn(!!session?.user);
      }
    );
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/tandc" element={<Terms />} />
            <Route
              path="/contactus"
              element={<Contactus supabase={supabase} />}
            />
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/otpVerify" element={<OtpVerify />} />
            <Route path="/deleteprofile" element={<DeleteProfile />} />
            <Route
              path="/subscriptionscreen"
              element={<SubscriptionScreen />}
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <PrivateRoute>
                  <Logout />
                </PrivateRoute>
              }
            />
            <Route
              path="/addfixture"
              element={
                <PrivateRoute>
                  <Addfixture />
                </PrivateRoute>
              }
            />
            <Route
              path="/chatscreen"
              element={
                <PrivateRoute>
                  <ChatScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/loginaftersigningup"
              element={
                <PrivateRoute>
                  <LoginAfterSigningUp />
                </PrivateRoute>
              }
            />
            <Route
              path="/clubProfile"
              element={
                <PrivateRoute>
                  <ClubProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/clublocation"
              element={
                <PrivateRoute>
                  <ClubLocation />
                </PrivateRoute>
              }
            />
            <Route
              path="/clubList"
              element={
                <PrivateRoute>
                  <ClubList />
                </PrivateRoute>
              }
            />
            <Route
              path="/editProfile"
              element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
